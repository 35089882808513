<template>
    <div id="app" class="wrapper">
        <Header
            name="standard"
        />
        <router-view />
        <Footer />
    </div>
</template>

<script>
    "use strict";

    import Footer from "@/components/TheFooter.vue";
    import Header from "@/components/TheHeader.vue";

    import setting from "@/mixins/setting.js";

    export default {
        "components": {
            Footer,
            Header,
        },
        "mixins": [
            setting,
        ],
    };
</script>

<style lang="scss">
    .wrapper {
        background: #333;
        margin: 0 auto;
        max-width: 420px;
    }

    .content {
        background: #fff;
        margin: 0 16px;
        padding: 16px 12px;
        @include sp {
            margin-top: 16px;
        }
    }

    .pagetitle {
        font-weight: bold;
        line-height: 1.4;
        margin: 0 0 16px;
        text-align: left;
    }

    .buttonArea {
        margin-top: 40px;
        text-align: center;
        div {
            &:not(:first-child) {
                margin-top: 40px;
            }
        }
    }
</style>
